<template>
  <div class="block-box">
    <el-table :data="transactionList" style="width: 100%">
      <el-table-column :label="$t('transaction.交易哈希')">
        <template #default="scope">
            <router-link class="text-ellipsis" :to="{ name: 'TransactionDetial', params: { hash: scope.row.hash }}">{{ scope.row.hash }}</router-link>
          </template>
      </el-table-column>
      <el-table-column
        width="100px"
        :label="$t('block.区块')"
      >
        <template #default="scope">
                <router-link :to="{name: 'BlockDetial', params: { blockNumber: scope.row.blockNumber }}">{{ scope.row.blockNumber }}</router-link>
              </template>
      </el-table-column>
      <el-table-column :label="$t('transaction.发送方')">
       <template #default="scope">
                <router-link class="text-ellipsis" :to="{name: 'AddressDetial', params: { address: scope.row.fromAddress }}">{{ scope.row.fromAddress }}</router-link>
              </template>
      </el-table-column>
      <el-table-column :label="$t('transaction.接收方')">
        <template #default="scope">
                <router-link v-if="scope.row.toAddress" class="text-ellipsis" :to="{name: 'AddressDetial', params: { address: scope.row.toAddress }}">{{ scope.row.toAddress }}</router-link>
                <span class="text-ellipsis" v-else>{{$t('transaction.创建合约')}}</span>
              </template>
      </el-table-column>
      <el-table-column :label="$t('transaction.价值')">
        <template #default="scope">
          <span class="text-ellipsis"
            >{{ web3.utils.fromWei(scope.row.ethValue, "ether") }} ds</span
          >
        </template>
      </el-table-column>
      <el-table-column :label="$t('transaction.交易费用')">
        <template #default="scope">
          <span class="text-ellipsis"
            >{{ web3.utils.fromWei(scope.row.gasPrice, "Gwei") }} Gwei</span
          >
        </template>
      </el-table-column>
      <el-table-column :label="$t('transaction.时间')">
        <template #default="scope">
          <span class="text-ellipsis">{{
            dateFormat(
              "yyyy-MM-dd hh:mm:ss",
              new Date(scope.row.timestamp * 1000)
            )
          }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-pagination-box">
      <el-pagination
        background
        :page-size="limit"
        layout="prev, pager, next"
        @current-change="currentChange"
        :current-page="Number(page)"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";
import { getTransactionAllList } from "@/api";
import { useI18n } from "vue-i18n"
export default {
  components: {},
  setup() {
    const router = useRouter();
    const { t } = useI18n()
    const route = useRoute();
    const data = reactive({
      $t: t,
      page: route.query.page || 1,
      limit: 30,
      total: 0,
      transactionList: [],
      currentChange: number => {
        router.replace({ name: "Transaction", query: { page: number } });
      },
      toDetail: (row, column, event) => {
        router.push({ name: "TransactionDetial", params: { hash: row.hash } });
      }
    });
    onMounted(() => {
      getTransactionAllList({ page: data.page, limit: data.limit }).then(
        res => {
          data.transactionList = res.data.list;
          data.total = res.data.total;
        }
      );
    });
    onBeforeRouteUpdate((to, from) => {
      data.page = to.query.page || 1;
      getTransactionAllList({ page: data.page, limit: data.limit }).then(
        res => {
          data.transactionList = res.data.list;
          data.total = res.data.total;
        }
      );
    });
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
};
</script>

<style lang="scss" scoped>
.page-pagination-box {
  text-align: right;
  margin-top: 30px;
}
</style>
