<template>
  <div class="block-box">
    <el-table :data="blockList" style="width: 100%">
      <el-table-column :label="$t('token.合约地址')">
        <template #default="scope">
                <router-link :to="{name: 'AddressDetial', params: { address: scope.row.contract }}">{{ scope.row.contract }}</router-link>
          </template>
      </el-table-column>
      <el-table-column :label="$t('token.代币简称')">
        <template #default="scope">
          <span class="text-ellipsis">{{ scope.row.symbol }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="decimals"
       :label="$t('token.代币精度')"
      ></el-table-column>
      <el-table-column
        prop="totalSupply"
        :label="$t('token.代币总量')"
      ></el-table-column>
    </el-table>
    <div class="page-pagination-box">
      <el-pagination
        background
        :page-size="limit"
        layout="prev, pager, next"
        @current-change="currentChange"
        :current-page="Number(page)"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";
import { getTokenAllList } from "@/api";
import { useI18n } from "vue-i18n"
export default {
  components: {},
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n()
    const data = reactive({
      $t: t,
      page: route.query.page || 1,
      limit: 30,
      total: 0,
      blockList: [],
      currentChange: number => {
        router.replace({ name: "Token", query: { page: number } });
      },
      toDetail: (row, column, event) => {
        router.push({
          name: "BlockDetial",
          params: { blockNumber: row.number }
        });
      }
    });
    onMounted(() => {
      getTokenAllList({ page: data.page, limit: data.limit }).then(res => {
        data.blockList = res.data.list;
        data.total = res.data.total;
      });
    });
    onBeforeRouteUpdate((to, from) => {
      data.page = to.query.page || 1;
      getTokenAllList({ page: data.page, limit: data.limit }).then(res => {
        data.blockList = res.data.list;
        data.total = res.data.total;
      });
    });
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
};
</script>

<style lang="scss" scoped>
.page-pagination-box {
  text-align: right;
  margin-top: 30px;
}
</style>
